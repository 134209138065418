import React, { useCallback, useEffect, useState} from 'react';
import {Wrapper} from "./styled";
import Input from "../../../components/Input";
import {ReactComponent as SearchIcon} from "../../../assets/img/search.svg";
import {ReactComponent as EditIcon} from "../../../assets/img/edit.svg";
import {ReactComponent as DeleteIcon} from "../../../assets/img/delete.svg";
import {ReactComponent as CloseIcon} from "../../../assets/img/clear.svg";
import {CustomerInfoInterface, CustomerRequestInterface} from "../../../types/customer";
import DataTable from "react-data-table-component";
import Pagination from "../../../components/Pagination";
import {ProductDiscountItem} from "../../../types/productDiscountTypes";
import CustomerGroupsCreateEdit from "./CustomerGroupsCreateEdit";
import {
  getAllCustomerGroupForProductDiscountAxios,
  getAllCustomersAndProductsForProductDiscountAxios,
  removeCustomerGroupForProductDiscountAxios,
} from "../../../api/customers";
import GroupDetail from "./GroupDetail/GroupDetail";
import RemoveModal from "../../../components/RemoveModal/RemoveModal";

const ProductDiscountCustomerGroup = () => {
  const [showCreateGroupForm, setShowCreateGroupForm] = useState(false);
  const [showRemoveGroupForm, setShowRemoveGroupForm] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<any>(null)
  const [productList, setProductList] = useState<ProductDiscountItem[]>([]);
  const [customersList, setCustomerList] = useState<any[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const [customersGroupsList, setCustomersGroupsList] = useState<any[]>([]);
  const [productListInfo, setProductListInfo] = useState<CustomerInfoInterface>({
    count_users: 0,
    limit: 0,
    page: 0,
    total_pages_count: 0,
    total_users_count: 0,
  });
  const [requestParamtrs, setRequestParamtrs] =
    useState<CustomerRequestInterface>({
      limit: "10",
      page: "1",
      search: null,
    });
  const [isLoading, setIsLoading] = useState(false);
  const [listRow, setListRow] = useState<any>({});
  
  const customersGroupsTableColumns = [
    {
      name: "Group Name",
      minWidth: "15%",
      cell: (row: any, index: number): JSX.Element => {
        return (
          <div>
            {row.name}
          </div>
        );
      },
    },
    {
      name: "Group Description",
      minWidth: "15%",
      // maxWidth: "150px",
      cell: (row: any, index: number): JSX.Element => {
        return (
          <div>
            {row.description}
          </div>
        );
      },
    },
    {
      name: "Group Discount",
      minWidth: "15%",
      // maxWidth: "150px",
      cell: (row: any, index: number): JSX.Element => {
        return (
          <div>
            {row.discount}
          </div>
        );
      },
    },
    {
      name: "Customers Count",
      minWidth: "15%",
      cell: (row: any, index: number): JSX.Element => {
        return (
          <div>
            {row.customersCount
            }
          </div>
        );
      },
    },
    {
      name: "",
      minWidth: "10%",
      cell: (row: any): JSX.Element => {
        return (
          <div className="action">
            <button
              type="button"
              className="edit"
              onClick={() => setEditCustomerGroup(row)}
            >
              <EditIcon/>
            </button>
            <button
              type="button"
              className="delete"
              onClick={() => selectGroupToRemove(row)}
            >
              <DeleteIcon/>
            </button>
          </div>
        );
      },
    },
  ];
  
  const selectGroupToRemove = (group: any) => {
    setShowRemoveGroupForm(true)
    setSelectedGroup(group)
  }
  
  const setEditCustomerGroup = (group: any) => {
    setShowCreateGroupForm(true)
    setSelectedGroup(group)
  }
  const removeCustomerGroup = (id: number) => {
    removeCustomerGroupForProductDiscountAxios({id}).then(res => {
      setShowRemoveGroupForm(false)
      setSelectedGroup(null)
      getAllCustomersGroupForProductDiscount()
    })
  }
  
  const getAllCustomersGroupForProductDiscount = useCallback(() => {
    getAllCustomerGroupForProductDiscountAxios(requestParamtrs).then(res => {
      setCustomersGroupsList(res.data.data ? res.data.data : []);
    })
  }, [requestParamtrs])
  
  const getAllCustomersAndProduct = useCallback((id: number) => {
    getAllCustomersAndProductsForProductDiscountAxios(id).then(res => {
      setProductList(res.data.productDiscounts ? res.data.productDiscounts : []);
      setCustomerList(res.data.users ? res.data.users : []);
    })
  }, [])
  
  useEffect(() => {
    // getProductDiscounts()
    getAllCustomersGroupForProductDiscount()
  }, [requestParamtrs]);
  
  return (
    <Wrapper>
      <div className="page-title">Product discounts</div>
      <div className="top-bar staff-top-bar">
        <div className="search-wrap">
          <Input
            placeholder="Search..."
            required
            icon={<SearchIcon/>}
            getValue={(value) =>
              setRequestParamtrs({...requestParamtrs, search: value})
            }
          />
        </div>
        <button
          type="button"
          className="btn-white staff-add-btn"
          style={{marginBottom: "12px", marginRight: "20px"}}
          onClick={() => setShowCreateGroupForm(true)}
        >
          Add New Customers Group
        </button>
      
      </div>
      {showCreateGroupForm && (<div className="create-new-user">
          {
            <div className=" discountRule_wrapper loading-innerwrap">
              <div className="title-close">
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowCreateGroupForm(false)}
                >
                  <CloseIcon/>
                </button>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div className="input-wrap" style={{width: '100%'}}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                  }}>
                    <CustomerGroupsCreateEdit setShowCreateGroupForm={setShowCreateGroupForm}
                                              getAllCustomersGroupForProductDiscount={getAllCustomersGroupForProductDiscount}
                                              selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup}/>
                  </div>
                </div>
              </div>
            </div>
          }
        
        </div>
      )}
      <div className={`table-wrap ${isLoading ? "loading-blue" : ""}`}>
        <div className="table">
          <DataTable
            noHeader
            columns={customersGroupsTableColumns}
            data={customersGroupsList}
            expandableRows
            expandableRowExpanded={(row) => (row === listRow)}
            onRowExpandToggled={(bool, row) => {
              setListRow(row);
              setCustomerList([])
              setProductList([])
              getAllCustomersAndProduct(+row.id)
              setSelectedGroupId(+row.id)
            }}
            expandableRowsComponent={
              <GroupDetail productList={productList} selectedGroupId={selectedGroupId} show={true}
                           customerList={customersList} setProductList={setProductList}
                           getAllCustomersGroupForProductDiscount={getAllCustomersGroupForProductDiscount}
                           getProductDiscounts={getAllCustomersAndProduct} setIsLoading={setIsLoading}/>
            }
          />
          <Pagination
            {...productListInfo}
            requestLimit={requestParamtrs.limit}
            setRequestParamtrs={setRequestParamtrs}
          />
        </div>
      </div>
      
      <RemoveModal showModal={showRemoveGroupForm} setShowModal={setShowRemoveGroupForm} title={`Are you sure you want to delete the customer group "
                ${selectedGroup?.name}"?`} handleCancel={() => {
        setSelectedGroup(null)
        setShowRemoveGroupForm(false)
      }} handleConfirm={() => {
        removeCustomerGroup(selectedGroup!.id)
      }}/>
    
    </Wrapper>
  );
};

export default ProductDiscountCustomerGroup;
