import React, { FC, useState, useRef } from "react";
import { Line } from "react-chartjs-2";
import { Wrapper } from "./styled";
import { Props, IActiveChart } from "./types";

const DashboardChart: FC<Props> = ({
  promotionType,
  customers,
  salesStatistic,
  discount,
  dateFilter,
  endDate,
  statisticByLocation,
}) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [activeChart, setActiveChart] = useState<IActiveChart>("Gross Sales");

  const handleChangeActiveChart = (chartName: IActiveChart) => {
    setActiveChart(chartName);
    // todo
    setTimeout(() => {
      chartRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 200);
  };


  return (
    <Wrapper>
      <div className="nav-subpages">
        <div
          className={`nav-subpage ${
            activeChart === "Gross Sales" ? "active-page" : ""
          }`}
        >
          <button
            type="button"
            onClick={() => handleChangeActiveChart("Gross Sales")}
          >
            Gross Sales
          </button>
        </div>
        <div
          className={`nav-subpage ${
            activeChart === "Discount Issued" ? "active-page" : ""
          }`}
        >
          <button
            type="button"
            onClick={() => handleChangeActiveChart("Discount Issued")}
          >
            Discount Issued
          </button>
        </div>
        <div
          className={`nav-subpage ${
            activeChart === "Net Sales" ? "active-page" : ""
          }`}
        >
          <button
            type="button"
            onClick={() => handleChangeActiveChart("Net Sales")}
          >
            Net Sales
          </button>
        </div>
      </div>
      <div ref={chartRef} className="chart-wrapper">
        <div className="chart">
            <Line
              // type="line"
              height={80}
              data={{
                labels: customers
                  .map((date, i) => {
                    const day = new Date(endDate);
                    if (dateFilter === 365) {
                      const newDate = new Date(
                        day.getFullYear(),
                        day.getMonth() - i,
                        1
                      );
                      return `${newDate.toLocaleString("en", {
                        month: "short",
                      })}`;
                    }
                    day.setDate(day.getDate() - i);
                    return `${day.getDate()} ${day.toLocaleString("en", {
                      month: "short",
                    })}`;
                  })
                  .reverse(),
                datasets: [
                  {
                    data:
                      activeChart === "Gross Sales" ? customers : activeChart === "Discount Issued" ? discount : salesStatistic,
                    borderColor:
                      activeChart === "Gross Sales" ? "#00A8FF" : activeChart === "Discount Issued" ? "#FF0000": "#2ECC71",
                    pointRadius: 6,
                    borderWidth: 2,
                    pointBorderWidth: 2,
                    pointBorderColor: "#fff",
                    // hoverRadius: 8,
                    pointBackgroundColor:
                      activeChart === "Gross Sales" ? "#00A8FF" : activeChart === "Discount Issued" ? "#FF0000": "#2ECC71",
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    displayColors: false,
                    intersect: false,
                    callbacks: {
                      title(tooltipItem: any[]) {
                        const date = new Date();

                        if (dateFilter === 365) {
                          const newDate = new Date(
                            date.getFullYear(),
                            date.getMonth() + tooltipItem[0].dataIndex + 1,
                            1
                          );
                          return `${newDate.toLocaleString("en", {
                            month: "short",
                          })} ${newDate.getFullYear() - 1}`;
                        }
                        date.setDate(
                          date.getDate() -
                            (tooltipItem[0].dataset.data.length -
                              tooltipItem[0].dataIndex -
                              1)
                        );

                        return `${tooltipItem[0].label}, ${date.getFullYear()}`;
                      },
                      label(tooltipItem: any) {
                        return `${
                          activeChart === "Gross Sales"
                            ? "Gross Sales"
                            : activeChart === "Discount Issued"
                            ? "Discount Issued"
                            : "Net Sales"
                        }: ${tooltipItem.formattedValue}`;
                      },
                    },
                  },
                },
                animation: {
                  duration: 0,
                },
                scales: {
                  x: {
                    beginAtZero: true,
                    ticks: {
                      precision: 0,
                    },
                  },
                  y: {
                    beginAtZero: true,
                    ticks: {
                      precision: 0,
                    },
                  },
                },
              }}
            />
        </div>
      </div>
    </Wrapper>
  );
};

export default DashboardChart;
