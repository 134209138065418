import axios from "./api";
import {
  posCategoryEditItemRequest,
  posCategoryItemRequest, posStampCategoryRequest
} from "../types/posRyles";

export const getAllCategoriesAxios = (): Promise<any> =>
  axios
    .get("/api/pos-categories-view")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const setCategoryAxios = (
  requestParameters:posCategoryItemRequest
): Promise<any> =>
  axios
    .post("/api/pos-categories-create", requestParameters)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const setPosStampCategoryAxios = (
  requestParameters:posStampCategoryRequest
): Promise<any> =>
  axios
    .post("/api/pos-stamp-categories-create", requestParameters)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getPosStampCategoryAxios = (): Promise<any> =>
  axios
    .get("/api/pos-stamp-categories-view")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const removeCategoryAxios = (
  categoryId: string
): Promise<any> =>
  axios
    .post(`/api/pos-categories-delete/${categoryId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const removeStampCategoryAxios = (
  categoryId: string
): Promise<any> =>
  axios
    .post(`/api/pos-stamp-categories-delete/${categoryId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const editCategoryAxios = (
  requestParameters: posCategoryEditItemRequest
): Promise<any> =>
  axios
    .post("/api/pos-categories-edit", requestParameters)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const editStampCategoryAxios = (
  requestParameters: posStampCategoryRequest
): Promise<any> =>
  axios
    .post("/api/pos-stamp-categories-edit", requestParameters)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
